import { onLCP, onFID, onCLS, onFCP, onTTFB } from 'web-vitals'
import { trackEvent } from 'lib/tracking/analytics-service'

export const webVitalsTracking = () => {
    onCLS((data) => trackEvent('web_vitals_cls', { valueId: data.value * 1000000 }))
    onFID((data) => trackEvent('web_vitals_fid', { valueId: data.value * 1000 }))
    onLCP((data) => trackEvent('web_vitals_lcp', { valueId: data.value * 1000 }))
    onFCP((data) => trackEvent('web_vitals_fcp', { valueId: data.value * 1000 }))
    onTTFB((data) => trackEvent('web_vitals_ttfb', { valueId: data.value * 1000 }))
}
