import { AnalyticsEventPageView } from 'lib/constants/AnalyticsEventType'
import { trackEvent } from 'lib/tracking/analytics-service'
import { getSearchOption } from './storage'

export const pageViewTracking = () => {
    const params = new URLSearchParams(window.location.search)
    const referrerScreen = params.get('referrerScreen') ?? undefined
    const search = getSearchOption()

    trackEvent(AnalyticsEventPageView, { value: window.location.pathname, referrerScreen, search })
}
