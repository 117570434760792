import { Know, KnowInfoLocalization, KnowStatus, KnowTranscript } from 'interfaces/Know'
import { Language } from 'interfaces/Language'
import { ParsedLocale } from 'lib/constants/localization'
import { TFunction } from 'i18next'
import { downloadFile } from 'lib/features/download'
import theme from 'stories/utils/theme'

export function formatKnowStatus(t: TFunction<'knows'>, knowStatus: KnowStatus, o?: { hasContentCampaignRunning: boolean }) {
    switch (knowStatus) {
        case KnowStatus.Public:
            return t('knows/knowStatusPublic')
        case KnowStatus.InReview:
        case KnowStatus.InExtendedReview:
            return t(o?.hasContentCampaignRunning ? 'knows/knowStatusExpansionInReview' : 'knows/knowStatusInReview')
        case KnowStatus.Draft:
            return t('knows/knowStatusDraft')
        case KnowStatus.Rejected:
        case KnowStatus.PermanentlyRejected:
            return t('knows/knowStatusPermanentlyRejected')
    }
}

export function getKnowStatusLabelColor(knowStatus: KnowStatus) {
    switch (knowStatus) {
        case KnowStatus.Public:
            return theme.colors.happyGreen
        case KnowStatus.InReview:
        case KnowStatus.InExtendedReview:
        case KnowStatus.Draft:
            return theme.colors.accentOrange
        case KnowStatus.Rejected:
        case KnowStatus.PermanentlyRejected:
            return theme.colors.errorRed
        default:
            return theme.colors.actionGray
    }
}

export const generateRandomAlphaLetter = () => {
    const alpha = 'ABCDEFGHIGKLMNOPQRSTUVWXYZ'
    return alpha.charAt(Math.floor(Math.random() * alpha.length))
}

export const getRandomLocalizedComment = ({ contentLanguageCode }: ParsedLocale) => {
    let comments: string[] = []
    switch (contentLanguageCode) {
        case Language.German:
            comments = [
                'Vielen Dank, wirklich hilfreich für mich, da wir gerade genau das Thema in der Schule haben 😁',
                'So ein schöner Lernzettel 😍😍 super nützlich und hilfreich!',
                'Cool, mit dem Lernzettel konnte ich mich richtig gut auf meine Klassenarbeit vorbereiten. Danke 👍👍',
            ]
            break
        case Language.Polish:
            comments = [
                'Dzięki, bardzo mi to pomoże, ponieważ teraz uczymy się tego 😁',
                'Piękna notatka 😍😍 bardzo mi się przyda dzięki!',
                'Fajnee, będę się z tego uczyć, ale jeszcze tego nie mieliśmy. Pozdrawiam 👍👍',
            ]
            break
        case Language.Italian:
            comments = [
                'Grazie, mi aiuterà molto perché stiamo insegnando questo argomento ora! 😁',
                'Un appunto così carino per la scuola 😍😍, è davvero utile!',
                'Fantastico, imparerò da questo appunto oggi. Saluti 👍👍',
            ]
            break
        case Language.AmericanEnglish:
        case Language.BritishEnglish:
            comments = [
                `Thanks a lot, this is really helpful since we're learning about it right now 😁`,
                'Such a pretty note 😍😍 this will come in handy!',
                `Coool, I'll be learning from this today. Cheers 👍👍`,
            ]
            break
        case Language.French:
            comments = [
                `Merci beaucoup, c'est vraiment utile d'autant plus que nous sommes en train de l'apprendre en ce moment 😁`,
                `Quel belle fiche 😍😍 c'est sûr qu'elle va beaucoup aider`,
                `Coool, je vais réviser à partir de cela aujourd'hui. Cheers 👍👍`,
            ]
            break
        default:
            break
    }
    return comments[Math.floor(Math.random() * comments.length)]
}

export const getAltTextFromTranscript = (knowTranscript: KnowTranscript | null) => {
    return knowTranscript ? knowTranscript?.transcript?.slice(0, 140) : 'Know Preview'
}

export const returnTranscriptText = (transcript: string | null, isBeginning: boolean) => {
    if (!transcript) return null

    const parts = transcript.split(' ')

    const isLongerThan200Words = parts.length > 200
    if (isBeginning) {
        if (isLongerThan200Words) {
            return `${parts.slice(0, 200).join(' ')}...`
        } else {
            return parts.join(' ')
        }
    } else {
        if (isLongerThan200Words) {
            return parts.slice(200).join(' ')
        }
        return null
    }
}

export const getMatchingLocalizationInfo = (
    localization: KnowInfoLocalization[] | null,
    { contentLanguageCode }: ParsedLocale
) => {
    if (!localization?.length) return null

    return localization.find((localization) => localization.language.code === contentLanguageCode) ?? null
}

export const handleDownloadKnowContent = async (know: Know) => {
    const contentUrl = know.documents[0].contentUrl

    const pdfBlob = await fetch(contentUrl).then((res) => res.blob())

    downloadFile(pdfBlob, `${know.title}.pdf`)
}
