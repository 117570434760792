import { trackFacebookEvent, FacebookEventTypes } from 'lib/tracking/facebook'
import { webVitalsTracking } from 'lib/tracking/webVitals'
import { pageViewTracking } from 'lib/tracking/page-view'

export function onPageEnter() {
    if (typeof window === 'undefined') return

    pageViewTracking()
    webVitalsTracking()
    trackFacebookEvent(FacebookEventTypes.PageView)
}
